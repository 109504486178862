import create from 'zustand';
import { getConfigSettings } from '@apis';
import { decodeBase64 } from '@constants';

const useConfigStore = create((set) => ({
  DECRYPT_FIRST_KEY: null,
  DECRYPT_SECOND_KEY: null,
  FIREBASE_API_KEY: null,
  GTM_KEY: null,
  isConfigLoaded: false,
  fetchConfigSettings: async () => {
    try {
      set({ isConfigLoaded: true });

      const settings = await getConfigSettings();

      const decodedSettings = decodeBase64(settings);

      if (!settings) {
        throw new Error('Config settings are empty!');
      }

      const firebaseKey = decodedSettings.slice(0, 39);
      const decryptFirstKey = decodedSettings.slice(39, 59);
      const decryptSecondKey = decodedSettings.slice(59, 79);
      const gtmKeyMatch = decodedSettings.slice(79, 90);

      if (!gtmKeyMatch) {
        throw new Error('Invalid GTM_KEY format!');
      }

      const gtmKey = gtmKeyMatch[0];

      set({
        FIREBASE_API_KEY: firebaseKey,
        DECRYPT_FIRST_KEY: decryptFirstKey,
        DECRYPT_SECOND_KEY: decryptSecondKey,
        GTM_KEY: gtmKey,
      });
    } catch (error) {
      console.error('Failed to fetch or parse config settings:', error);
    }
  },
}));

export default useConfigStore;
