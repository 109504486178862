import apiInstance from '../axios';
import { logApiError, logApiResponse } from '@utils';
import {
  CREATE_REQUEST,
  UPDATE_REQUEST,
  GET_VEHICLE_INFO,
  GET_DEALERSHIP_INFO,
  GET_CONSULT_INFO,
  GET_HPP_MODELS,
  GET_DEALERSHIP_COUNTRYCODE,
  GET_RESERVATION_INFO,
  CHECK_DUPLICATION,
  SEARCH_AUTOCOMPLETE,
  GET_PRIVACY_POLICY,
  UPDATE_LEAD,
  ADD_CHAT,
  GET_TOKEN_REQUESTID,
  GET_MESSAGE_LIST,
  GET_BROCHURE,
} from '@constants';

export const getModelByModelId = async (modelId, countryCode) => {
  const data = {
    modelId,
    country: countryCode,
  };
  // const encryptedBody = await encryptData(data);
  try {
    const response = await apiInstance.post(GET_VEHICLE_INFO, { encryptedBody });
    logApiResponse('getModelByModelId', response);
    return response;
  } catch (error) {
    logApiError('getModelByModelId', error);
  }
};

export const getDealershipByDealershipCode = async (dealershipCode) => {
  try {
    // const encryptedBody = await encryptData({ dealershipCode });
    const response = await apiInstance.post(GET_DEALERSHIP_INFO, { encryptedBody });
    logApiResponse('getDealershipByDealershipCode', response);
    return response;
  } catch (error) {
    logApiError('getDealershipByDealershipCode', error);
  }
};

export const getConsultInfoByCountryCode = async (countryCode) => {
  try {
    const response = await apiInstance.get(GET_CONSULT_INFO, {
      params: { countryCode },
    });
    logApiResponse('getConsultInfoByCountryCode', response);
    return response;
  } catch (error) {
    logApiError('getConsultInfoByCountryCode', error);
  }
};

export const getHppVehicleModels = async (countryCode) => {
  try {
    const response = await apiInstance.get(GET_HPP_MODELS, {
      params: { country: countryCode },
    });
    logApiResponse('getHppVehicleModels', response);
    return response;
  } catch (error) {
    logApiError('getHppVehicleModels', error);
  }
};

export const getDealershipByCountry = async (coordinates) => {
  try {
    const response = await apiInstance.get(GET_DEALERSHIP_COUNTRYCODE, {
      params: coordinates,
    });
    logApiResponse('getDealershipByCountry', response);
    return response;
  } catch (error) {
    logApiError('getDealershipByCountry', error);
  }
};

export const getReservationInfoByDealer = async (dealershipCode, date, timeUnit) => {
  try {
    const response = await apiInstance.get(GET_RESERVATION_INFO, {
      params: { dealershipCode, date, timeUnit },
    });
    logApiResponse('getReservationInfoByDealer', response);
    return response;
  } catch (error) {
    logApiError('getReservationInfoByDealer', error);
  }
};

export const checkDuplicationReservation = async (dealershipCode, date) => {
  try {
    const response = await apiInstance.get(CHECK_DUPLICATION, {
      params: { dealershipCode, date },
    });
    logApiResponse('checkDuplicationReservation', response);
    return response;
  } catch (error) {
    logApiError('checkDuplicationReservation', error);
  }
};

export const searchAutoComplete = async (keyword, countryCode) => {
  try {
    const response = await apiInstance.post(SEARCH_AUTOCOMPLETE, {
      searchText: keyword,
      countryCode,
    });
    logApiResponse('searchAutoComplete', response);
    return response;
  } catch (error) {
    logApiError('searchAutoComplete', error);
  }
};

export const getPrivacyPolicyByCountryCode = async (countryCode) => {
  try {
    const response = await apiInstance.get(GET_PRIVACY_POLICY, {
      params: { countryCode },
    });
    logApiResponse('getPrivacyPolicyByCountryCode', response);
    return response;
  } catch (error) {
    logApiError('getPrivacyPolicyByCountryCode', error);
  }
};

export const createHLiveRequest = async (requestData) => {
  try {
    // const encryptedBody = await encryptData(requestData);
    const response = await apiInstance.post(CREATE_REQUEST, { encryptedBody });
    logApiResponse('createHLiveRequest', response);
    return response;
  } catch (error) {
    logApiError('createHLiveRequest', error);
  }
};

export const updateServiceRequest = async (requestData) => {
  try {
    // const encryptedBody = await encryptData(requestData);
    const response = await apiInstance.post(UPDATE_REQUEST, { encryptedBody });
    logApiResponse('updateServiceRequest', response);
    return response;
  } catch (error) {
    logApiError('updateServiceRequest', error);
  }
};

export const updateLeadData = async (requestId) => {
  try {
    const response = await apiInstance.post(UPDATE_LEAD, { requestId });
    logApiResponse('updateLeadData', response);
    return response;
  } catch (error) {
    logApiError('updateLeadData', error);
  }
};

export const addChat = async (dto) => {
  try {
    const response = await apiInstance.post(ADD_CHAT, { dto });
    logApiResponse('addChat', response);
    return response;
  } catch (error) {
    logApiError('addChat', error);
  }
};

export const getMessageList = async (dto) => {
  try {
    const response = await apiInstance.post(GET_MESSAGE_LIST, { dto });
    logApiResponse('getMessageList', response);
    return response;
  } catch (error) {
    logApiError('getMessageList', error);
  }
};

export const getTokenByRequestId = async (requestId) => {
  try {
    const response = await apiInstance.post(GET_TOKEN_REQUESTID, { requestId });
    logApiResponse('getTokenByRequestId', response);
    return response;
  } catch (error) {
    logApiError('getTokenByRequestId', error);
  }
};

export const getModelBrochure = async (requestData) => {
  try {
    // const encryptedBody = await encryptData(requestData);
    const response = await apiInstance.post(GET_BROCHURE, { encryptedBody });
    logApiResponse('getModelBrochure', response);
    return response;
  } catch (error) {
    logApiError('getModelBrochure', error);
  }
};
